@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap'); */

@font-face {
  font-family: 'Inter';
  /* src: url('https://local.geohub.ai:3001/fonts/webui/Inter/Inter-Medium.ttf'); */
  src: url('./../public/fonts/webui/Inter/Inter-Medium.ttf');
  /* src: local('./fonts/webui/Inter/Inter-Medium.ttf'); */
  font-weight: normal;
  font-style: normal;


}

body {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background-image: url("https://images.unsplash.com/photo-1519681393784-d120267933ba?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1124&q=100");
  background-position: center;

  background-repeat: no-repeat;
  background-size: cover; */

}

code {
  font-family: 'Inter', sans-serif !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1C1C1C;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(68, 68, 68, 1);
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(68, 68, 68, 1);
}

.divLogNav {
  align-items: center;
  text-align: center;
  display: flex;
}

.divCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.img-svg-icon {
  max-width: 16px;
  height: 16px
}

.img-svg-icon-add {
  max-width: 28px;
  height: 28px
}

.img-svg {
  max-width: 40px;
  height: 40px
}

.divBtnPagination {
  border: 1px solid #FFFFFF;
  min-height: 24px;
  min-width: 24px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.divBtnPaginationAction {
  border: none;
  min-height: 30px;
  min-width: 30px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #272D3C !important;
  border-radius: 2px;

  -webkit-box-shadow: inset 0 1px 2px -2px rgb(138, 138, 138);
  -moz-box-shadow: inset 0 1px 2px -2px rgb(138, 138, 138);
  box-shadow: inset 0 1px 2px -2px rgb(138, 138, 138);
}

.divBtnPaginationAction:hover {
  background: #2D3342 !important;
}

.pre {
  background-color: black;
}

.next {
  background-color: #FFFFFFB2
}



.active {
  justify-content: left;
  /* border-left-color: #60CDFF;
  border-left-width: 3px; */
  display: block;
  /* border-right-color: transparent;
  border-top-color: transparent;
  border-bottom-color: transparent; */
  background: #272D3C;
  border-radius: 3px;
  border: none !important;
  width: 100%;

}

.active-menu {
  height: inherit;
  width: 3px;
  background: url(./assets/images/Selector.png) center left no-repeat;
}

.div-menu-btn {
  display: flex;
  height: 36px;
  align-items: center;
  width: 258px;
}

.div-menu-btn.active {
  background: #272D3C;
  border-radius: 3px;
  /* width: 34px !important; */
}

.div-menu-btn::before {
  content: '';
  position: relative;
  width: 3px;
  right: 0;
  /* top: 8px; */
  background: transparent;
  height: 50%;
  border-radius: 12px
}

.div-menu-btn.active::before {
  background: #4CC2FF;
}


/* .div-menu-btn-active {
  display: flex;
  background: #272D3C;
  border-radius: 3px;
  height: 36px;
} */

.btn-cus {
  width: 100%;
  cursor: pointer;
  border: none;
  background: none
}

.btn-cus-primary {
  background: linear-gradient(0deg, #60CDFF, #60CDFF),
    linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
  border: 1px solid;

  border-image-source: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
  color: black !important;

  padding: 0px 10px 0px 10px !important
}

.card-item-download {
  width: 100%;
  max-width: 100%;
  height: 120px;
  background-color: rgba(255, 255, 255, 0.08) !important;
  border-radius: 3px !important;
  border: none !important;
  cursor: pointer;
  padding: 0 24px !important;
}

.card-item-order-img {
  width: 100%;
  max-width: 100%;
  height: 120px;
  background: #2E3240 !important;

  border-radius: 3px !important;
  border: none;
  cursor: pointer;
  box-shadow: none !important;
}

.card-item-drawernoti {
  width: 100%;
  max-width: 100%;
  /* background: #2E3240 !important; */
  border-radius: 4px !important;
  border: none;
  cursor: pointer;
  box-shadow: none !important;
  margin-bottom: 8px;
  display: block !important
}

.div-card-preview {
  /* margin-left: auto !important; */
  margin-top: auto !important;
  margin-bottom: auto !important;
  /* margin-right: 14px !important; */
  width: 70px !important;
  max-width: 70px !important;
}

.div-card-preview-item-scan {
  margin-left: auto !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  margin-right: 30px !important;
}



.card-item-storage {
  width: 100%;
  max-width: 100%;
  height: 78px;
  background: #2E323E;
  border-radius: 3px !important;
  border: none;
  cursor: pointer;
  box-shadow: none !important;
  margin-bottom: 8px;
  padding: 16px 24px 16px 24px !important;
  min-width: 400px !important;
}

.card-item-storage:hover {
  background: #3E4353 !important;

  .div-action-card {
    display: flex;
    align-items: center;
  }

  .div-action-card-scheduler {
    display: flex;
    align-items: center;
  }

  .footer-card-action {
    display: flex;
    justify-content: end;

    align-items: center;
    flex-direction: row !important;
  }

  .divHeader {
    width: calc(100% - 100px);
  }
}

.div-card-header {
  width: calc(100% - 70px - 150px) !important;
  height: 100% !important;
}

.footer-card-action-scheduler {
  display: flex;
  justify-content: end;

  align-items: center;
  flex-direction: row !important;
}

.div-action-card-footer-scheduler {
  display: flex;
  align-items: center;
}

.div-action-card-scheduler {
  display: none;
}

.card-item-resource {
  width: 100%;
  max-width: 100%;
  background: #2E3240 !important;

  border-radius: 3px !important;
  border: none;
  cursor: pointer;
  box-shadow: none !important;
}

.header-noti {
  margin-left: 0 !important
}

.divHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.divTitle {
  width: 45%;
}

.divSpot {
  width: 5%;
  text-align: center;
}

.divDes {
  width: 45%;
  padding: 0 10px 0 10px;
}

.divDungLuong {
  width: 5%;
  text-align: center;
}

.tag-accept {
  /* background: linear-gradient(0deg, #283E33, #283E33), linear-gradient(0deg, #1F6342, #1F6342);
  border: 1px solid rgba(31, 99, 66, 1); */
  border-radius: 4px;
  color: rgba(0, 230, 118, 1)
}

.tag-reject {
  /* background: linear-gradient(0deg, #3D2C28, #3D2C28),
    linear-gradient(0deg, #612C1F, #612C1F);
  border: 1px solid rgba(97, 44, 31, 1); */
  color: rgba(221, 44, 0, 1);
}

.tag-submit {
  color: yellow;
}

.accoding-item {
  background: #383838;
  border-radius: 4px;
  padding-bottom: 16px;
  border: 1px solid rgba(86, 86, 86, 1);
  margin-bottom: 12px
}

.buttonFliterSearh {
  background: #2C2C2C !important;
  height: 32px !important;
  width: 32px !important;
  min-width: 32px !important;
}

.buttonFliterSearh:focus {
  background: #60CDFF !important;

}

.buttonFliterSearh:focus-within {
  background: #60CDFF !important;

}

.buttonSearhMap {
  background: #2C2C2C !important;
}

.buttonSearhMap:focus {
  background: #60CDFF !important;

}

.buttonSearhMap:focus-within {
  background: #60CDFF !important;

}

.buttonSearhMap:active {
  background: #60CDFF !important;

}

.down-line {
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
}

.title {
  margin-bottom: 100px;
}

.div-step-item {
  text-align: center;
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.div-center {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.div-center-row {
  display: flex !important;
  align-items: center;
  text-align: center;
  justify-content: space-between;
}

.card-default {
  background: #2E3240 !important;
  padding: 25px !important;
  width: 100%;
  border: none !important;
  box-shadow: none
}

.card-default-active {
  background: rgba(86, 173, 213, 0.4) !important;
  border: 1px solid rgba(96, 205, 255, 1);
  padding: 25px !important;
  width: 100%;
  box-shadow: none;

  .bg-icon-card {
    background: rgba(96, 205, 255, 1);
    border-radius: 50%;
  }
}

.card-default:hover {

  background: rgba(86, 173, 213, 0.4);
  border: 1px solid rgba(96, 205, 255, 1);
  color: white;

  .bg-icon-card {
    background: rgba(96, 205, 255, 1);
    border-radius: 50%;
  }
}

.bg-icon-card {
  background: rgba(149, 149, 149, 1);
  border-radius: 50%;
}

.bg-icon-card-disable {
  background: rgba(149, 149, 149, 1) !important;
  border-radius: 50%;
}

.bg-icon-card:hover {
  background: rgba(96, 205, 255, 1);
}

.btn-back {
  background: none !important;
  border: 1px solid rgba(96, 205, 255, 1) !important;
  color: rgba(96, 205, 255, 1) !important;

}

.img-svg-icon-menu {
  max-width: 15px;
  height: 15px;
  position: absolute;
  right: 10px;
}

.img-svg-icon-filter {
  max-width: 15px;
  height: 15px;
}

.img-svg-icon-paging {
  max-width: 12px;
  height: 12px;
}

.radio-cus {
  position: absolute;
  left: 0px;
  top: 0px;
  width: calc(16px + 2 * var(--spacingHorizontalS));
  height: 100%;
  box-sizing: border-box;
  margin: 0px;
  opacity: 0;
}

.divStep {
  background: linear-gradient(0deg, rgb(28, 28, 28), rgb(28, 28, 28)), linear-gradient(0deg, rgb(86, 86, 86), rgb(86, 86, 86));
  border: 1px solid rgb(86, 86, 86);
  border-radius: 20px;
  padding: 8px;
  width: 50px;
  text-align: center;
}

.divFooterStep {
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  width: 97%;
  left: 50%;
  transform: translateX(-50%);
}

.btn-extra-action {

  background: #272D3C !important;
  border: none !important;
  width: 130px !important;
  cursor: pointer !important;
  font-weight: 100 !important;
  color: white !important;
  border-radius: 4px !important;
  padding: 6px 12px !important;
  height: 32px !important;
  margin-right: 14px !important;

  -webkit-box-shadow: inset 0 1px 2px -2px rgb(138, 138, 138);
  -moz-box-shadow: inset 0 1px 2px -2px rgb(138, 138, 138);
  box-shadow: inset 0 1px 2px -2px rgb(138, 138, 138);
}

.btn-extra-action:hover {
  background: #2D3342 !important;
}

.btn-action {
  background: rgb(96, 205, 255) !important;
  border: medium !important;

  width: 130px !important;
  color: black !important;
  cursor: default;
  font-weight: 100 !important;

  border-radius: 4px !important;
  padding: 6px 12px !important;
  height: 32px !important;
}

.cardfooter {
  align-content: flex-end;
  align-items: end;
  flex-direction: column !important;

}

.footer-card-action {
  /* display: flex;
  justify-content: space-between; */

  align-content: flex-end;
  align-items: end;
  flex-direction: column !important;
  width: 150px !important;
  max-width: 150px !important;
}

.footer-card-small-action {
  /* display: flex;
  justify-content: space-between; */

  align-content: flex-end;
  align-items: end;
  flex-direction: column !important;
  width: 50px !important;
  max-width: 50px !important;
}

.divSelectUserFilter {

  padding: 8px;
  border-radius: 4px;
  border-width: 1px;
  /* border-style: solid;
  border-color: currentcolor;
  border-image: linear-gradient(rgba(255, 255, 255, 0.093) 0%, rgba(255, 255, 255, 0.07) 9.57%); */
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #2E3240 !important;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: #9B9DA3 !important;
  height: 33px;
}

.btn-clear {
  background: rgb(56, 56, 56, 1) !important;
  border-color: rgb(56, 56, 56, 1) !important;
  margin-bottom: 8px !important;
}

.btn-apply {
  border: 1px solid rgba(255, 255, 255, 0.03) !important;
  width: 100% !important;
  background: rgb(96, 205, 255) !important;
  color: black !important;
}

.btn-action-card {
  width: 100%;
  cursor: pointer !important;
  background: none !important;
  border-radius: 4px !important;
  border: none !important;
  margin-right: 4px !important;
}

.group-count-badge {
  display: flex;
}

.count-badge {
  margin-left: -8px;
  margin-top: -10px;
}



.navbar-icon-2 {
  margin-right: 8px !important;
}

.menu-popover {
  top: 12px !important
}

.drawer {
  background-color: #232735 !important;
  width: 560px !important;
  top: 42px !important;
  bottom: 54px !important;
  padding: 0px 15px 10px;
  color: white;
}

.drawer-bg {
  background-color: #232735 !important;
  background-image: none !important;
}

.img-card-catalog {
  height: 32px !important;
  width: 32px !important;
}

.img-card-item-catalog {
  min-height: 32px !important;
  min-width: 32px !important;

  height: 32px !important;
  width: 32px !important;
}

.img-card-storage {
  height: 28px !important;
  width: 28px !important;
}

.div-card {
  background: #2E3240 !important;
  margin-bottom: 10px;
  border-radius: 4px;
}

.bg-default {
  background: #232735 !important;
}

.no-data {
  color: rgba(149, 149, 149, 1);
  margin-top: 60px
}

.btn-action-infor-item-dataset {
  margin-right: 8px !important;
  padding: 4px 12px !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  background: rgb(149, 149, 149) !important;
}

.div-preview-storage {
  height: 64px;
  width: 64px;
}

.div-breadcrumb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #232735;
}

.div-list {
  height: 100%;
  /* max-height: calc(100vh - 58px - 24px - 24px - 57px - 53px); */
  overflow: auto;
}

.div-list-order {
  height: calc(100% - 53px);
  /* max-height: calc(100vh - 58px - 24px - 24px - 57px - 53px); */
  overflow: auto;
}

.btn-add-list {
  width: 120px;
  cursor: pointer;
  margin-right: 4px;
  color: black;
}

.children {
  overflow: hidden;
  background: #232735 !important;
  display: flex;
  height: calc(100vh - 58px - 57px);
  /* overflow: auto; */
}

.color-black {
  color: black !important;
}

.btn-show-order {
  width: 100%;
  cursor: pointer;
  background: none !important;
  margin-right: 4px;
  border: none !important;
}

.text-color-des {
  color: #9B9DA3 !important;
}

.text-color-error {
  color: #DD2C00 !important;
}

.text-color-success {
  color: #00E676 !important;
}

.bg-default-order {
  background: #2E3240 !important;
}

.img-card-down {
  height: 64px !important;
  width: 64px !important;
  margin-left: 12px;
}

.div-datepicker {
  width: 70%;
  background: #272D3C !important;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: #9B9DA3 !important;
}

.div-datepicker-cus {
  background: #272D3C !important;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: #9B9DA3 !important;
}

.minWidthCus {
  min-width: auto !important;
}

.fui-TimePicker__input {
  width: 100% !important;
}

.btn-active-cart {
  margin-right: 8px;
  border: 1px solid rgba(255, 255, 255, 0.03) !important;
  background: rgb(96, 205, 255) !important;
  color: black !important;
  font-weight: 100;
}

.btn-cancel-cart {
  background: rgb(56, 56, 56) !important;
  border: 1px solid rgba(255, 255, 255, 0.03) !important;
  width: 100% !important;
}

.div-text-area {
  width: 100% !important;
  min-height: 300px !important;
  margin-top: 15px !important;
  background-color: #2E3240 !important;
}

.div-title-form-storage {
  font-size: 24px;
  font-weight: 600;
}

.div-element {
  width: 100% !important;
  background: #2E3240 !important
}

.div-form-storage {
  background: #232735;
  height: calc(-191px + 100vh);
  position: relative;
  padding: 45px 30px 30px;
  overflow: auto;
  /* border: 1px solid rgb(86, 86, 86); */
}

.div-form-storage-small {
  background: #232735;
  height: calc(-191px + 100vh);
  position: relative;
  padding: 45px 25% 45px 25%;
  overflow: auto;
  /* border: 1px solid rgb(86, 86, 86); */
}

.div-resource {
  overflow-y: auto;
  height: calc(-320px + 100vh);
}

.w-100 {
  width: 400px !important;
}

.div-user-quota {
  background: #232735;
  height: calc(-182px + 100vh);
  position: relative;
  padding: 45px 30px 30px;
}

.div-dropdown {
  width: 100%;
  background: #2E3240 !important;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: #9B9DA3 !important;
}


.btn-cancel-order {
  width: 100px;
  background: #2E3240 !important;
  border: 1px solid rgba(255, 255, 255, 0.03) !important;
}

.div-download {
  height: 57px;
  background: #2E3240;
  border: 1px solid rgba(86, 86, 86, 1);
  margin-bottom: 16px;
  border-radius: 2px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}

.btn-close-download {
  border-radius: 4px;
  background: #2E3240 !important;
  border: none !important;
  margin-right: 8px;
}

.btn-download {
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.03);
  background: rgba(82, 82, 82, 1) !important;
}

.item-download {
  border: 1px solid rgba(96, 205, 255, 1) !important;
  background: rgba(64, 75, 80, 1) !important;
}

.img-loading-download {
  width: 20px !important;
  height: 20px !important;
}

.card-item-download-footer {
  width: 100%;
  max-width: 100%;
  height: 35px;
  background-color: #2E3240 !important;
  border-radius: 3px !important;
  border: none !important;
  cursor: pointer;
}

.card-item-download-drawer {
  background: rgba(255, 255, 255, 0.08) !important;
  padding: 24px;
  border-radius: 3px;
  border: none !important;
  margin-bottom: 8px;
}

.img-svg-icon-down {
  max-width: 15px;
  height: 15px
}

circle {
  transition: all 0.7s ease-in-out;
}

.text-white {
  fill: white
}

.menuListNoti {
  width: 350px !important;
  margin-top: 10px !important;

}

.divMarkAll {
  width: 100%;

}

.divider {
  flex-grow: none !important;
}

.div-menuPopover {
  position: relative;
  height: fit-content;
  margin-top: 20px;
}

.btn-action-draw-download {
  max-width: 16px !important;
  min-width: 16px !important;
}

.footer-webui {
  display: flex !important;
  justify-content: space-between !important;
  border-top: 1px solid #353B4F;
  height: 56px;
}

.div-menu-list {
  position: absolute;
  left: 44px;
  z-index: 999999 !important;
  background: #1A2032;
  border-radius: 7px;
  width: 165px;

  .fui-MenuItem {
    background: none;
  }

}

.div-action-card {
  display: none;
}

/* .footer-card-action:hover .div-action-card {
  display: block;
} */

.header-order-item-page {
  display: flex;
  border-bottom: 1px solid #353B4F;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.btn-action-page-item-order {
  padding: 4px 16px;
  align-items: center;
  border-radius: 4px;
  background: #2D3342 !important;
  height: 28px;
  cursor: pointer;

  -webkit-box-shadow: inset 0 1px 2px -2px rgb(138, 138, 138);
  -moz-box-shadow: inset 0 1px 2px -2px rgb(138, 138, 138);
  box-shadow: inset 0 1px 2px -2px rgb(138, 138, 138);
}

.btn-action-page-item-order:hover {
  background: #2d3342ad !important;
}

.border-right-divider {
  border-right: 1px solid #9B9DA3;
  margin-right: 12px;
  margin-left: 12px;
  height: 14px;
}

.div-list-detail-order-admin {
  max-height: calc(100vh - 58px - 56px - 24px - 24px - 41px - 320px);
  overflow: auto;
}

.drawer-cus {
  /* width: 504px !important; */
  right: 0px;
  /* min-width: 504px; */
  overflow-x: auto !important;
  transition: all 0.3s ease 0s !important;
}


h2 {
  .fui-DrawerHeaderTitle__heading {
    width: calc(100% - 40px);
  }
}


.fui-DrawerHeaderTitle__heading {

  width: calc(100% - 32px);
}

.fui-DrawerHeaderTitle__action {
  margin-right: 0px !important;
}

.btn-action-drawer-info {
  display: flex !important;
  padding: 4px 12px 4px 8px;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  border-radius: 4px !important;
  border: none !important;
  font-weight: 100;
  font-size: 14px;

  -webkit-box-shadow: inset 0 1px 2px -2px rgb(138, 138, 138);
  -moz-box-shadow: inset 0 1px 2px -2px rgb(138, 138, 138);
  box-shadow: inset 0 1px 2px -2px rgb(138, 138, 138);
}

.btn-action-drawer {
  display: flex !important;
  padding: 4px 12px 4px 8px;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  border-radius: 4px !important;
  background: #272D3C !important;
  border: none !important;
  font-weight: 100;
  font-size: 14px;

  -webkit-box-shadow: inset 0 1px 2px -2px rgb(138, 138, 138);
  -moz-box-shadow: inset 0 1px 2px -2px rgb(138, 138, 138);
  box-shadow: inset 0 1px 2px -2px rgb(138, 138, 138);
}

.btn-action-drawer:hover {
  background: #2D3342 !important;
}

.div-btn-menu-type-action {
  display: flex;
}

.badge-menu {
  padding: 2px 8px !important;
  border-radius: 12px !important;
}

.badge-menu-not-expanded {
  border-radius: 50% !important;
  margin-left: -10px;
}

.img-icon-state {
  height: 20px !important;
  width: 20px !important;
}

.card-info-hc {
  background: #2E323E;
  border: none !important;
  border-radius: 4px;
  padding: 12px !important;
  width: 100%;
  height: 74px;
  /* align-items: center; */
}

.pie-hc {
  height: 260px !important;
  width: 260px !important;
}

.datepicker-cus {
  padding: 0 2px !important;

}

.text-title-card {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

.ellipsis-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
}

.ellipsis-text-one-line {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
}

.div-layout-content-open {
  width: calc(100vw - 268px) !important;
}

.div-layout-content-close {
  width: calc(100vw - 44px) !important;
}

.sidebar {
  position: relative;
  overflow: unset !important;
  min-height: 300px !important;
  opacity: 1 !important;
  background: #1a2030f0 !important;
  transition: all 0.5 ease;
}

.sidebar-open {
  width: 268px !important;
  max-width: 268px !important;
}

.sidebar-close {
  width: 44px !important;
  min-width: 44px !important;
  max-width: 44px !important;
}

.div-drawer-body {
  padding-left: 1.5rem
    /* 24px */
    !important;
  padding-right: 1.5rem
    /* 24px */
    !important;
  padding-top: 0px !important;
}

.div-card-preview-card-download {
  width: 120px !important;
  min-width: 120px !important;
}

.div-card-preview-card-none-download {
  width: 70px !important;
  min-width: 70px !important;
}

.div-preview-card-item-download-drawer {
  min-width: 30px !important;
}

.div-card-preview-item-drawer-search {
  /* margin-left: auto !important; */
  margin-top: auto !important;
  margin-bottom: auto !important;
  /* margin-right: 14px !important; */
  width: 50px !important;
  max-width: 50px !important;
  min-width: 50px !important;
}

.img-card-item-catalog-search-drawer {
  min-height: 50px !important;
  min-width: 50px !important;

  height: 50px !important;
  width: 50px !important;
}

.btn-active {
  background: #4CC2FF !important;
}

.card-item-collection-search-drawer {
  width: 100%;
  max-width: 100%;
  height: 78px;
  background: #2E323E;
  border-radius: 3px !important;
  border: none;
  cursor: pointer;
  box-shadow: none !important;
  margin-bottom: 8px;
  padding: 10px 16px 10px 16px !important;
  min-width: 400px !important;
}

.card-item-collection-search-drawer:hover {
  background: #3E4353 !important;
}

.div-card-header-item-collection-search-drawer {
  width: calc(100% - 80px) !important;
  height: 100% !important;
}

.width-infor {
  width: calc(100% - 80px) !important;
}


/* loading */
/* Absolute Center Spinner */
.loading {
  position: absolute;
  z-index: 999;
  height: 100%;
  width: 100%;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #090B13;

  opacity: 0.8;
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: none !important;
  font-size: 10px;
  width: 1em;
  height: 1em;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  /* margin-top: -0.5em; */
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* end loading */


@media screen and (max-width: 1366px) {
  .drawer-cus {
    width: 278px;
  }
}

@media screen and (max-width: 1218px) {
  .divTitle {
    width: 100%;
  }

  .divSpot {
    display: none;
  }

  .divDes {
    display: none;
  }

  .divDungLuong {
    display: none;
  }

  .div-form-storage-small {
    padding: 29px 10% 20px 10% !important;
  }
}

@media screen and (max-width: 980px) {
  .divHeader {
    width: 80%;
  }

  .card-item-download {
    max-width: 97%;
  }
}

@media screen and (max-width: 956px) {

  .card-item-download {
    max-width: 95%;
  }
}

@media screen and (max-width: 868px) {

  .card-item-download {
    max-width: 85%;
  }

  .div-form-storage-small {
    padding: 25px 10% 25px 10% !important;
  }
}

@media screen and (max-width: 800px) {

  .divTitle {
    display: none !important;
  }

  .sidebar-open {
    width: 158px !important;
  }

  .div-layout-content-open {
    width: calc(100vw - 158px) !important;
  }

  .card-item-storage {
    padding: 10px 16px 10px 16px !important;
  }

  .div-drawer-body {
    padding-left: 12px !important;
    padding-right: 12px !important;
    padding-top: 0px !important;
  }
}


@media screen and (max-height: 495px) {
  .divSubMenu {
    position: relative !important;
  }
}

.divSubMenu {
  bottom: 2px !important
}


/**************** Custom css theme *****************/

.fui-DialogSurface {
  background: #1a2030 !important;
}

/* Checkbox checked */

.f5opsb9>.fui-Checkbox__indicator {
  background: #4CC2FF !important;
  /* primary-500 */
}

.f1qq5vh1>.fui-Checkbox__indicator {
  border-bottom-color: #4CC2FF !important;
  /* primary-500 */
}

.f13mj44e>.fui-Checkbox__indicator {
  border-left-color: #4CC2FF !important;
  /* primary-500 */
}

.fub94a2>.fui-Checkbox__indicator {
  border-right-color: #4CC2FF !important;
  /* primary-500 */
}

.f1gqj827>.fui-Checkbox__indicator {
  border-top-color: #4CC2FF !important;
  /* primary-500 */
}

.carousel-status {
  display: none !important;
}

.fui-AccordionHeader__button {
  font-size: 11px !important;
}

.div-my-quota-info {
  height: 48px;
  /* background: #3E4353; */
  border-radius: 4px;
  padding: 6px 11px;
}

.fui-ProgressBar {
  background: #525769 !important;
}

.fui-Toaster {
  width: 350px !important;
}

.div-layout {
  background-image: url(./assets/background.avif) !important;
}



/* End Checkbox checked */


/* .maplibregl-canvas {
  width: 100% !important;
} */

/* start andimation change page */
/* .fade-appear {
  opacity: 0;
  z-index: 1;
  transform: translateX(100%);
  transition: 300ms linear;
}


.fade-enter {
  opacity: 0.5;
  z-index: 1;
  transform: translateX(20%);
  transition: 300ms linear;
}

.fade-enter-done {
  opacity: 1;
  transform: translateX(0%);
  transition: 300ms linear;
}

.fade-exit {
  opacity: 1;
  transform: translateX(0%);
  transition: 300ms linear;
}

.fade-exit-active {
  opacity: 0.5;
  transform: translateX(20%);
  transition: 300ms linear;
}

.fade-exit-done {
  opacity: 0;
  transform: translateX(100%);
  transition: 300ms linear;
} */



.fade-in-enter {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 150ms linear;
}

.fade-in-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: linear;
}

.fade-in-exit {
  opacity: 1;
  transform: translateX(0%);
  transition: linear;
}

.fade-in-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: linear;
}

.fade-in-enter-active,
.fade-in-exit-active {
  transition: opacity 150ms linear, transform 150ms linear;
}



.fade-out-enter {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 150ms linear;
}

.fade-out-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: linear;
}

.fade-out-exit {
  opacity: 1;
  transform: translateX(0%);
  transition: linear;
}

.fade-out-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: linear;
}

.fade-out-enter-active,
.fade-out-exit-active {
  transition: opacity 150ms linear, transform 150ms linear;
}
/* .page-in-enter {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 300ms ease, transform 300ms ease;
}

.page-in-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms ease, transform 300ms ease;
}

.page-in-exit {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms ease, transform 300ms ease;
}

.page-in-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 300ms ease, transform 300ms ease;
}

.page-out-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.page-out-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms ease, transform 300ms ease;
}

.page-out-exit {
  opacity: 1;
  transform: translateX(0);
}

.page-out-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 300ms ease, transform 300ms ease;
}


.fade-enter {
  opacity: 0;
  transform: translateX(100%);
}

.fade-enter-active {
  opacity: 1;
  transform: translateX(0%);
}

.fade-exit {
  opacity: 1;
  transform: translateX(0%);
}

.fade-exit-active {
  opacity: 0;
  transform: translateX(-100%);
}

.fade-enter-active,
.fade-exit-active {
  transition: opacity 1500ms, transform 300ms;
} */
/* end andimation change page */


.container-ping {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ping {
  background: red;
  border-radius: 50%;
  border: 5px solid #222;
  width: 10px;
  height: 10px;
  animation: load 1.5s ease-out infinite;
}

@keyframes load {
  0% {
    background: red;
    border: 0px solid red;
  }

  50% {
    background: red;
    border: 10px solid #222;
  }

  100% {
    background: #fff;
    border: 0px solid #222;
  }
}


.fui-AccordionHeader__button {
  border-radius: 0.25rem !important;
}

/**************** End Custom css theme *****************/